<template>
  <div>
    <setup-table
      :title="`Vehicle Condition Rate`"
      :items="items"
      :headers="headers"
      :loading="loading"
      :clearItem="!showForm || !editedItem.id"
      @createItem="createItem()"
      @editItem="editItem"
      @deleteItems="deleteItems"
    >
      <template #itemForm v-if="showForm">
        <vehicle-condition-rates-form
          ref="vehicleConditionRatesForm"
          :value="editedItem"
          @submit="saveItem"
          @closeForm="closeForm"
        ></vehicle-condition-rates-form>
      </template>

      <template #[`item.charge`]="{ item }">
        <p class="mb-0">${{ item.charge }}</p>
      </template>
    </setup-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_CONDITIONS, SAVE_CONDITION, DELETE_CONDITIONS } from '@/store/modules/VehicleConditionRate/actions';
import SetupTable from './SetupTable.vue';
import VehicleConditionRatesForm from './VehicleConditionRatesForm';

export default {
  name: 'VehicleConditionRatesTable',
  inject: ['eventHub'],
  components: { SetupTable, VehicleConditionRatesForm },
  data() {
    return {
      showForm: false,
      items: [],
      editedItem: {},
      loading: false,
      headers: [
        { text: 'Sequence No.', value: 'sequenceNumber' },
        { text: 'Condition', value: 'vehicleCondition' },
        { text: 'Charge', value: 'charge' },
      ],
    };
  },
  computed: {
    ...mapGetters('app', ['isRoutingClient']),
    ...mapGetters('vehicleConditionRate', ['vehicleConditionRatesList']),
  },
  mounted() {
    this.getItems();
  },
  methods: {
    ...mapActions('vehicleConditionRate', [GET_CONDITIONS, SAVE_CONDITION, DELETE_CONDITIONS]),
    async getItems() {
      this.loading = true;
      await this.getVehicleConditionRates({ forTable: true });
      this.items = this.vehicleConditionRatesList;
      this.loading = false;
    },
    createItem() {
      this.showForm = true;
      this.editedItem = { id: 0, ttOnly: false };
    },
    editItem(item) {
      this.editedItem = { ...item };
      this.showForm = true;
    },
    async saveItem(item) {
      try {
        const response = await this.saveVehicleConditionRate(item);
        if (response && response.done) {
          this.$myalert.success('Vehicle Condition Rate saved');
          this.showForm = false;
          await this.getItems();
          this.editedItem = { ...item, id: response.id };
        }
      } catch (error) {
        this.$myalert.error(error);
      }
    },
    async deleteItems(items) {
      const text = items.length > 1 ? 'these Condition Rates' : 'this Condition Rate';
      const confirmed = await this.$myconfirm(
        `Are you sure you want to delete ${text}? This operation cannot be undone.`
      );
      if (confirmed) {
        const response = await this.deleteVehicleConditionRates(items.map((item) => parseInt(item.id)));
        if (response.done) {
          this.$myalert.success(`${items.length > 1 ? 'Vehicle Types' : 'Vehicle Type'} deleted`);
          this.showForm = false;
          await this.getItems();
        }
      }
    },
    closeForm() {
      this.showForm = false;
    },
  },
};
</script>
