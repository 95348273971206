<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="12">
          <v-btn @click="cancel(false)"> cancel </v-btn>
          <v-btn
            v-if="canEditSetupTables"
            class="float-right white--text"
            color="green"
            :disabled="$v.form.$invalid"
            @click="onSubmit"
          >
            save
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-card>
      <v-form :readonly="value.id > 0 && !canEditSetupTables">
        <v-container>
          <v-row class="my-0 py-0" dense>
            <v-col cols="12" md="4">
              <v-text-field
                :value="value.sequenceNumber"
                type="number"
                v-model.trim="$v.form.sequenceNumber.$model"
                label="Sequence Number"
                required
                min="0"
                :error-messages="handleErrors($v.form.sequenceNumber)"
                @blur="$v.form.sequenceNumber.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :value="value.vehicleCondition"
                v-model.trim="$v.form.vehicleCondition.$model"
                label="Condition"
                required
                :error-messages="handleErrors($v.form.vehicleCondition)"
                @blur="$v.form.vehicleCondition.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :value="value.charge"
                type="number"
                v-model.trim="$v.form.charge.$model"
                label="Charge"
                required
                min="0"
                :error-messages="handleErrors($v.form.charge)"
                @blur="$v.form.charge.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, numeric } from 'vuelidate/lib/validators';
import { handleErrors } from '@/util';
const newVehicleConditionRate = {
  id: 0,
  sequenceNumber: '',
  vehicleCondition: '',
  charge: '',
  ttOnly: false,
};
export default {
  mixins: [validationMixin],
  validations() {
    return {
      form: {
        sequenceNumber: { required },
        vehicleCondition: { required },
        charge: { required, numeric },
        ttOnly: {},
      },
    };
  },
  props: {
    value: {
      type: Object,
      default: () => newVehicleConditionRate,
    },
  },
  data: () => ({
    handleErrors,
    id: null,
    form: newVehicleConditionRate,
    saved: false,
  }),
  computed: {
    ...mapGetters('user', ['me', 'canEditSetupTables']),
    ...mapGetters('app', ['isRoutingClient']),
  },
  methods: {
    onSubmit() {
      if (this.$v.form.$invalid) {
        this.$v.form.$touch();
        this.$myalert.error('Please complete all required fields');
        return;
      }
      this.$emit('submit', { id: this.id, ...this.$v.form.$model });
      this.saved = true;
    },
    async cancel(fromSave) {
      let ok = true;
      if (!fromSave)
        ok = await this.$myconfirm('Are you sure you want to close this form? Your changes will not be saved.');
      if (ok) {
        this.form = newVehicleConditionRate;
        this.$emit('closeForm');
      }
    },
    handleInput(address) {
      this.address = address;
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.id = value.id;
        if (value.id && this.saved) {
          this.saved = false;
          this.cancel(true);
        } else this.form = { ...newVehicleConditionRate, ...value };
      },
    },
  },
};
</script>

<style scoped></style>
